<template>
  <time class="countdown flex" :datetime="new Date(expiresAt).toISOString()">
    {{ timestamp }}
  </time>
</template>

<script lang="ts" setup>
import { type Duration, isPast } from 'date-fns'

import { duration } from '~/utils/date'

type CountdownEmit = {
  (e: 'expired'): void
  (e: 'update'): void
}

type CountdownProps = {
  expiresAt: Date | string
  variant?: 'full' | 'without-hours'
}

const ONE_SECOND_TICK = 1000

const ZEROED_COUNTDOWN: Duration = {
  hours: 0,
  minutes: 0,
  seconds: 0,
}

const emit = defineEmits<CountdownEmit>()

const props = withDefaults(defineProps<CountdownProps>(), {
  variant: 'full',
})

const intervalId = ref<NodeJS.Timeout>()
const isExpired = ref(false)
const now = useState(() => new Date())

const handleExpired = () => {
  isExpired.value = true
  emit('expired')
  clearInterval(intervalId.value)
}

const handleTick = () => {
  now.value = new Date()
  emit('update')
}

const isCoutdownExpired = () => isPast(new Date(props.expiresAt))

const countdown = computed<Duration>(() => {
  if (isExpired.value) return ZEROED_COUNTDOWN

  const { hours, minutes, seconds } = duration(
    now.value,
    new Date(props.expiresAt),
  )

  return {
    ...ZEROED_COUNTDOWN,
    hours: hours || 0,
    minutes: minutes || 0,
    seconds: seconds || 0,
  }
})

const timestamp = computed(() => {
  const { hours, minutes, seconds } = countdown.value

  const timestamp = `${pad(minutes)}:${pad(seconds)}`
  if (props.variant === 'without-hours') return timestamp
  return `${pad(hours)}:${timestamp}`
})

onMounted(() => {
  if (process.server) return

  intervalId.value = setInterval(() => {
    if (isCoutdownExpired()) handleExpired()
    else handleTick()
  }, ONE_SECOND_TICK)
})

onBeforeUnmount(() => clearInterval(intervalId.value))
</script>

<style lang="scss" scoped>
.countdown {
  gap: 0.094rem;
  font-weight: $font-weight-medium;
}
</style>
