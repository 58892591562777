<template>
  <div
    class="flex flex-col items-end"
    :class="[`closing-time-${variant}`, { 'single-line': singleLine }]"
  >
    <span
      class="caption items-center text-secondary700 flex gap-2xs leading-sm font-regular"
      :class="{ animated: isTimeAlmostUp }"
    >
      <img
        v-if="isTimeAlmostUp"
        alt=""
        src="~/assets/animated-icons/alarm.svg"
      />
      <LcIcon v-else :name="isCutOffToday ? 'alarm' : 'time'" size="small" />

      <span class="time-to-cut-off">
        {{
          $t(
            `lottery.closingtime.caption.${
              isCutOffToday ? 'timetocutoff' : 'cutofftime'
            }`,
          )
        }}
      </span>

      <span class="time-is-almost-up">
        {{ $t('lottery.closingtime.caption.timeisalmostup') }}
      </span>
    </span>

    <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
    <span v-if="singleLine" class="divider mr-2xs">:</span>

    <div class="countdown-wrapper caption font-medium">
      <Countdown
        v-if="isCutOffToday"
        class="countdown"
        :expires-at="cutOffDate"
        @expired="emit('closing-time-is-reached')"
        @update="now = new Date()"
      />

      <DateTime v-else :datetime="drawingDate" format="drawingShort" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LcIcon } from '@lottocom/frontend-components'
import { isPast } from 'date-fns'

enum TimeUnit {
  Days = 'days',
  Minutes = 'minutes',
}

type ClosingTimeEmit = {
  (e: 'closing-time-is-reached'): void
}

type ClosingTimeProps = {
  cutOffTime: Date | string
  nextCutOffTime?: Maybe<Date | string>
  singleLine?: boolean
  startAnimationTime?: number
  variant?: 'expressive' | 'simple'
}

const emit = defineEmits<ClosingTimeEmit>()

const props = withDefaults(defineProps<ClosingTimeProps>(), {
  nextCutOffTime: null,
  singleLine: false,
  startAnimationTime: 90,
  variant: 'simple',
})

const now = useState(() => new Date())

const getRemainingTime = (unit: TimeUnit): number => {
  const {
    days = 0,
    hours = 0,
    minutes = 0,
  } = duration(now.value, cutOffDate.value)

  switch (unit) {
    case TimeUnit.Days:
      return days
    case TimeUnit.Minutes:
      return days * 24 + hours * 60 + minutes
    default:
      return 0
  }
}

const cutOffDate = computed(() => new Date(props.cutOffTime))

const drawingDate = computed(() =>
  isPast(cutOffDate.value) && props.nextCutOffTime
    ? props.nextCutOffTime
    : cutOffDate.value,
)

const isCutOffToday = computed(() => !getRemainingTime(TimeUnit.Days))

const isTimeAlmostUp = computed(
  () =>
    isCutOffToday.value &&
    getRemainingTime(TimeUnit.Minutes) < props.startAnimationTime,
)
</script>

<style lang="scss" scoped>
.caption {
  font-size: $font-size-caption;
}

.countdown {
  width: 3.3rem;
}

.time-is-almost-up {
  display: none;
}

.animated {
  position: relative;

  .time-is-almost-up {
    position: absolute;
    right: 0;
    display: unset;
    animation: time-to-cut-off 5s linear infinite;
  }

  .time-to-cut-off {
    animation: time-is-almost-up 5s linear infinite;
  }
}

.single-line {
  flex-direction: row;
  align-items: center;

  .caption {
    font-size: $font-size-md;
  }

  .countdown {
    min-width: 3.75rem;
  }

  @media screen and (width <= 350px) {
    flex-direction: column;
    align-items: flex-end;

    .divider {
      display: none;
    }
  }
}

@keyframes time-to-cut-off {
  0%,
  44%,
  100% {
    opacity: 0;
  }

  46%,
  98% {
    opacity: 1;
  }
}

@keyframes time-is-almost-up {
  0%,
  44%,
  100% {
    opacity: 0;
  }

  2%,
  42% {
    opacity: 1;
  }
}

.closing-time-expressive {
  padding: spacing('2xs') spacing('xs');
  border-radius: $border-radius-md 0 0 $border-radius-md;
  background: color('secondary200');

  .countdown-wrapper {
    font-size: 1.1rem;
  }

  .countdown {
    width: 4.6rem;
  }
}
</style>
